<template>
  <div class="flex flex-row w-full h-full">
    <TabView class="flex flex-column w-full h-full " id="congaAi-tabView">
      <TabPanel class="flex flex-row w-full justify-content-center h-full align-items-center" header="Chat Bot" >
        <div class="flex flex-row w-full h-full" style="max-height: calc(100vh - 15vh);">
          <div class="flex flex-column w-2">
            <SidebarComponent class="flex flex-column w-full h-full " :items="sidebarItems" @chatSelected="selectChat"
              @update-chat-name="updateChatName" @deleteChat="deleteChat" @newChat="() => {
                chatHistory = [];
                selectedChatId = undefined;
              }">
            </SidebarComponent>
          </div>
          <div class="flex flex-column h-full align-items-center justify-content-end w-10" >
            <div id="chatArea" ref="chatAreaRef" class="flex flex-column w-full h-full overflow-y-scroll align-items-center pt-4 gap-3">
              <div v-for="message in chatHistory" :key="message.chatId!"
                class="flex w-9 flex-column gap-2 justify-content-center ">
                <div class="flex flex-row w-full justify-content-start "
                  :class="{ 'justify-content-end': message.type === 'user', 'justify-content-start': message.type === 'assistant' }">
                  <div class="logo flex align-items-end justify-content-end">
                    <i class="pi pi-microchip-ai " v-if="message.type === 'assistant' || message.type === 'chat'"
                      style="font-size: 35px; margin-right: 5px;"></i>
                  </div>
                  <div class="flex w-full flex-column bubble left"
                    v-if="message.type === 'assistant' || message.type === 'chat'">
                    <div class="w-full align-items-center justify-content-center ">
                      <p style="word-wrap: break-word;" v-if="message.type !== 'chat'" v-html="message.context"></p>
                      <div v-else class="flex flex-row w-full h-4rem justify-content-start align-items-end gap-1 pb-2">
                        <div class="bouncingball"></div>
                        <div class="bouncingball"></div>
                        <div class="bouncingball"></div>
                      </div>
                    </div>
                  </div>

                  <div class="flex w-7 flex-column bubble right "  v-else>
                    <div class="w-full align-items-center justify-content-center " style="overflow-x:auto; ">
                      <p style="overflow-wrap: break-word;" v-html="message.context"></p>
                    </div>
                  </div>

                  <div class="logo flex align-items-end justify-content-end ">
                    <i class="pi pi-user " v-if="message.type === 'user'"
                      style="font-size: 35px; margin-right: 5px;"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex flex-row w-7 justify-content-between align-items-end py-2 pl-4 pr-2 gap-2 mb-4 mt-6 surface-card"
              style="border: 1px solid black; border-radius: 26px;">
              <Textarea class="p-0 w-full w-min-full border-transparent focus:border-transparent" autoResize v-model="chatMessage"
                placeholder="Ask something ..." style="max-height: 150px; min-height:2rem !important; "
                @keydown.enter.exact="chat" @keydown.shift.enter.exact="chatMessage + '\n'" :disabled="isLoading === true"></Textarea>
              <Button style="height:2.5rem; width:2.5rem;" class="mr-1" rounded size="small" icon="pi pi-arrow-up"
                @click="chat"></Button>
            </div>
          </div>
        </div>


      </TabPanel>
      <TabPanel class="flex flex-column w-full justify-content-center align-items-center" header="Image Bot">
        <div style="text-align: center;">
          <h1 style="color: var(--primary-color); text-decoration: underline;">congaAI (Beta Phase)</h1>
        </div>
        <div class="flex flex-column align-items-center justify-content-center w-full">
          <div class="flex flex-row w-full align-items-center justify-content-center">
            <InputText @keydown.shift.enter.exact="dispatchImageGenerationwithAI" class="w-4 custom-InputText" v-model="dallInput" id="dallInput"
              placeholder="Generate picture...">
            </InputText>
            <div class="flex align-items-center justify-content-center" style="margin-right: 1rem;">
              <Button label="Generate Picture" class="text-center " style="margin-left: 1rem;"
                @click="dispatchImageGenerationwithAI"></Button>
              <select v-model="selectedImageSize" class="custom-SizeSelect"
                style="margin-left: 1rem; width: 120px; margin-top: 0rem;">
                <option value="Size1792x1024">1729x1024</option>
                <option value="Size1024x1792">1024x1792</option>
                <option value="Size1024x1024" selected>1024x1024</option>
                <option value="Size256x256">256x256</option>
              </select>
            </div>
          </div>
          <div class="w-8" style="text-align: center; margin-top: 1rem;">

            <img v-if="!imageIsLoading && generatedImage" :src="generatedImage"
              :style="{ width: selectedImageSize.split('x')[0] + 'px', height: selectedImageSize.split('x')[1] + 'px' }"
              alt="Generated Image" />
            <ProgressSpinner v-if="imageIsLoading"></ProgressSpinner>
          </div>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>
<script setup lang="ts">
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import TabView from "primevue/tabview";
import Textarea from "primevue/textarea";
import SidebarComponent from "./SidebarComponent.vue";
import TabPanel from "primevue/tabpanel";
import { OpenAiService } from "@/apis/azureai-api";
import { ref, onMounted, inject } from "vue";
import hljs from 'highlight.js';
import 'highlight.js/styles/agate.css';
import { type ChatSession } from "@/apis/azureai-api";
import DOMPurify from 'dompurify';
import { markedHighlight } from "marked-highlight";
import { Marked } from "marked";
import ProgressSpinner from 'primevue/progressspinner';
export type congaAiProps = {
  data: {
    appChat: string

  }
};

const chatAreaRef = ref<HTMLElement | null>(null);
const dialogRef = ref<congaAiProps | undefined>(inject('dialogRef'));

const selectedImageSize = ref("Size1024x1024");
const generatedImage = ref<string | null>(null); //Store the base64-encoded string of the image
const sidebarItems = ref<ChatSession[]>([])
const isLoading = ref(false);
const chatMessage = ref("");
const dallInput = ref("");
const chatHistory = ref<ChatSession[]>([]);
const selectedChatId = ref();
const imageIsLoading = ref(false)


function scrollToBottom() {
  if (chatAreaRef.value) {
    chatAreaRef.value.scrollTop = chatAreaRef.value.scrollHeight;
  }
}
const markedHJs = new Marked(
  markedHighlight({
    langPrefix: 'hljs language-',
    highlight(code, lang, info) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, { language }).value;
    }
  })
)


onMounted(() => {
  if (dialogRef.value) {
    const params = dialogRef.value.data;
    chatMessage.value = params.appChat;
    chat();
  }
  fetchChatsAsync();
})



async function fetchChatsAsync() {
  isLoading.value = true;
  sidebarItems.value = (await OpenAiService.getApiV1OpenAiGptSessions1({})).chatSession || [];
  sidebarItems.value = sidebarItems.value.reverse();
  isLoading.value = false;
}


async function chat() {
  let question = chatMessage.value;
  chatMessage.value = '';
  isLoading.value = true;
  if (question.trim().length > 0) {
    chatHistory.value.push({
      type: 'user',
      context: DOMPurify.sanitize(markedHJs.parse(`\`\`\` \n${question}\n \`\`\` `)),
    })
    scrollToBottom();
    chatHistory.value.push({
      type: 'chat',
      context: 'upsi whupsi'
    })
    try {
      const apiResponse = await OpenAiService.postApiV1OpenAiGptChats({ question: question, context: question, chatId: selectedChatId.value });
    chatHistory.value[chatHistory.value.length - 1] = ({
      type: 'assistant',
      context: DOMPurify.sanitize(markedHJs.parse(apiResponse.response || "")),
      chatId: apiResponse.chatId
    })
    chatHistory.value[chatHistory.value.length - 2].chatId = apiResponse.chatId

    if (selectedChatId.value == undefined) {
      fetchChatsAsync()
    }
    selectedChatId.value = apiResponse.chatId;
    } catch (error) {
      console.log(error)
    }finally{
      scrollToBottom();
      isLoading.value = false;
    }
    
  }


}

async function deleteChat(chatId: number) {
  await OpenAiService.deleteApiV1OpenAiGptSessions({
    chatId: chatId
  });
  if (selectedChatId.value == chatId) {
    chatHistory.value = [];
  }
  sidebarItems.value = sidebarItems.value.filter(x => x.chatId != chatId)
}

async function selectChat(chatId: number) {
  chatHistory.value = [];
  selectedChatId.value = chatId;
  chatHistory.value = (await OpenAiService.getApiV1OpenAiGptSessions(selectedChatId.value)).contextGpt || [];
  for (let i = 0; i < chatHistory.value.length; i++) {
    let chat = chatHistory.value[i]
    if(chat.type === 'user'){
      chatHistory.value[i].context = DOMPurify.sanitize(markedHJs.parse( `\`\`\` \n ${ chat.context} \n \`\`\` ` || ""))
    }else{
      chatHistory.value[i].context = DOMPurify.sanitize(markedHJs.parse(chatHistory.value[i].context || ""))
    }
    
  }

}

async function dispatchImageGenerationwithAI() {
  imageIsLoading.value = true;
  const response = await OpenAiService.postApiV1OpenAiDalleImages({ prompt: dallInput.value, size: selectedImageSize });
  if (response && response.imageUri) {
    generatedImage.value = 'data:image/png;base64,' + response.imageUri;
  }
  imageIsLoading.value = false;
}

async function updateChatName(item: ChatSession) {
  await OpenAiService.putApiV1OpenAiGptSessions({ chatId: item.chatId!, newChatName: item.chatName });
  fetchChatsAsync(); // Refresh the sidebar items
}

</script>

<style>
.bubble {
  --r: 1em;
  /* the radius */
  --t: 1.5em;
  /* the size of the tail */

  padding: 1em;
  border-inline: var(--t) solid #0000;
  border-radius: calc(var(--r) + var(--t))/var(--r);
  mask:
    radial-gradient(100% 100% at var(--_p) 0, #0000 99%, #000 102%) var(--_p) 100%/var(--t) var(--t) no-repeat,
    linear-gradient(#000 0 0) padding-box;

  color: #fff;
}

.left {
  --_p: 0;
  border-bottom-left-radius: 0 0;
  place-self: start;
  background-color: #5f5f5f;
}

.right {
  --_p: 100%;
  border-bottom-right-radius: 0 0;
  place-self: end;
  background-color: #808080;
}


.p-tabview-panels {
  width: 100%;
  height: 100%;
  padding: 0;
}

.p-tabview-panel {
  height: 100%;
}

.bouncingball {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  animation: loading .75s linear infinite;
  background: var(--surface-card);

}

.bouncingball:nth-child(1) {
  animation-delay: .25s;
}

.bouncingball:nth-child(2) {
  animation-delay: .5s;
}

.bouncingball:nth-child(3) {
  animation-delay: .75s;
}

@keyframes loading {

  0%,
  100% {
    transform: translateY(.5px);
  }

  50% {
    transform: translateY(-5px);
  }
}

.p-tabview-nav ul {
  padding: 0;
}

#congaAi-tabView .p-tabview-panels {
  max-height: calc(100% - 85px);
}

pre {
    white-space: pre-wrap;       
    white-space: -moz-pre-wrap;  
    white-space: -pre-wrap;      
    white-space: -o-pre-wrap;    
    word-wrap: break-word;
    font-family: "Helvetica" !important;    
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}
</style>